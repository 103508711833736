<template>
  <div>
    <v-container fluid>
      <div class="main">
        <v-row>
          <v-col cols="12">
            <div class="d-flex justify-space-between mb-3 align-center">

              <NavAccounts/>

              <v-spacer/>

              <v-btn class="rounded-lg mr-2 v-btn--active"
                     color="gifty"
                     text
                     @click="$refs.resentNotificationDialog.open(selected_ids)"
                     :disabled="!selected_ids.length"
                     depressed
              >
                <v-icon left>mdi-bell-ring</v-icon>
                Renvoyer la notification
              </v-btn>

              <v-btn class="rounded-lg"
                     color="gifty"
                     dark
                     depressed
                     @click="$refs.importAccountsDialog.open()"
              >
                <v-icon left>mdi-import</v-icon>
                Importer
              </v-btn>
            </div>

            <v-card class="rounded-lg shadow">
              <v-overlay :value="overlay" absolute color="white" opacity="0.5">
                <v-progress-circular
                    color="primary"
                    indeterminate
                    size="50"
                ></v-progress-circular>
              </v-overlay>

              <v-card-title>
                <div>
                  <v-text-field
                      v-model="keyword"
                      append-icon="mdi-magnify"
                      clearable
                      dense
                      filled
                      hide-details
                      placeholder="Rechercher..."
                      rounded
                      single-line

                  />
                </div>
                <v-spacer/>

                <v-btn color="grey"
                       text
                       @click="getAccounts">
                  <v-icon left>mdi-refresh</v-icon>
                  Mettre à jour
                </v-btn>

              </v-card-title>

              <v-divider/>

              <v-card-text v-if="isLoading">
                <v-skeleton-loader type="table"/>
              </v-card-text>

              <div v-else>
                <v-card-text class="pa-0">
                  <v-simple-table v-if="accounts.length">
                    <template v-slot:default>
                      <thead>
                      <tr>
                        <th>
                          <v-icon class="pa-0 mt-0"
                                  :color="selected_ids.length ? 'primary' : ''"
                                  hide-details
                                  @click="toggleAll"
                                  @mousedown.prevent
                          >
                            {{ icon }}
                          </v-icon>

                        </th>
                        <th>Nom complet</th>
                        <th>Nom d'utilisateur</th>
                        <th>Téléphone</th>
                        <th>Email</th>
                        <th>Connecté</th>
                        <th>Créé le</th>
                        <th>Notifié</th>
                        <th>Actions</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="(account ,index) in accounts"
                          :key="index"
                      >

                        <td>
                          <v-checkbox v-model="selected_ids"
                                      :value="account.id"
                                      hide-details
                                      multiple
                                      class="pa-0 ma-0"></v-checkbox>
                        </td>

                        <td class="font-weight-medium d-flex align-center text-no-wrap">
                          <div v-if="account.name">
                            <v-avatar size="35">
                              <v-img
                                  v-if="account.image"
                                  :src="fileUrl + account.image"
                              />
                              <v-img
                                  v-else
                                  :src="require('@/assets/avatar.png')"
                              />
                            </v-avatar>
                            &nbsp;
                            {{ account.name }}
                          </div>
                          <div v-else>
                            -
                          </div>
                        </td>

                        <td>
                          {{ account.username ?? '-' }}
                        </td>

                        <td>
                          {{ account.phone ?? '-' }}
                        </td>

                        <td>
                          {{ account.email ?? '-' }}
                        </td>

                        <td>
                          <v-icon v-if="account.created_at !== account.updated_at" color="success">
                            mdi-check-circle
                          </v-icon>
                          <v-icon v-else color="red">mdi-close-circle</v-icon>
                        </td>

                        <td class="text-no-wrap">
                          {{ dateFormat(account.created_at) }}
                        </td>


                        <td>
                          <v-icon v-if="account.notified" color="success">
                            mdi-check-circle
                          </v-icon>
                          <v-icon v-else color="red">mdi-close-circle</v-icon>
                        </td>


                        <td>
                          <v-btn icon
                                 @click="remove(account.id)">
                            <v-icon>mdi-delete-outline</v-icon>
                          </v-btn>
                        </td>

                      </tr>
                      </tbody>
                    </template>
                  </v-simple-table>

                  <div v-else>
                    <NoResults @reload="getAccounts"/>
                  </div>
                </v-card-text>

                <v-divider v-if="total > 10"/>

                <v-card-actions v-if="total > 10">

                  <div :style="{width: '120px'}">
                    <v-select v-model="perPage"
                              :items="[10,20,30,50,100]"
                              dense
                              hide-details
                              label="Ligne par page"
                              outlined></v-select>
                  </div>

                  <v-spacer/>

                  <v-pagination v-model="pagination.current"
                                :length="pagination.total"
                                circle
                                total-visible="8"
                                @input="getAccounts"></v-pagination>

                </v-card-actions>

              </div>
            </v-card>
          </v-col>
        </v-row>
      </div>

      <ResentNotificationDialog ref="resentNotificationDialog"/>
      <ImportAccountsDialog ref="importAccountsDialog" @refresh="getAccounts"/>

    </v-container>
  </div>
</template>

<script>
import {HTTP} from "@/http-common";
import NoResults from "@/components/NoResults";
import ImportAccountsDialog from "@/views/accounts/components/ImportAccountsDialog";
import ResentNotificationDialog from "@/views/accounts/components/ResentNotificationDialog";
import NavAccounts from "@/views/accounts/components/NavAccounts";
import moment from "moment";

export default {
  components: {NavAccounts, ImportAccountsDialog, ResentNotificationDialog, NoResults},
  data() {
    return {
      fileUrl: process.env.VUE_APP_FILE_URL,
      keyword: '',
      id: '',
      overlay: false,
      isLoading: false,
      accounts: [],
      selected_ids: [],

      total: 0,
      perPage: 10,
      pagination: {
        current: 1,
        total: 0
      },
    };
  },
  methods: {
    dateFormat(date) {
      return moment(date).format('DD/MM/YYYY, HH:mm');
    },
    getAccounts() {
      this.isLoading = true
      HTTP.get('/v1/accounts?page=' + this.pagination.current, {
        params: {
          perPage: this.perPage,
          keyword: this.keyword
        }
      }).then(res => {
        this.pagination.current = res.data.data.current_page;
        this.pagination.total = res.data.data.last_page;
        this.total = res.data.data.total;

        this.accounts = res.data.data.data

        this.isLoading = false
      }).catch(err => {
        this.isLoading = false
        console.log(err)
      })
    },
    remove(id) {
      this.id = id;
      this.$confirm_dialog = true;
    },
    deleteItem() {
      this.overlay = true;
      HTTP.post("/v1/accounts/delete", {
        id: this.id,
      }).then(() => {
        this.overlay = false;
        let i = this.accounts.findIndex((el) => el.id === this.id);
        this.$delete(this.accounts, i);
        this.id = null;
        this.$successMessage = "Cet élément a été supprimé avec succès.";
      }).catch((err) => {
        this.overlay = false;
        console.log(err);
      });
    },
    toggleAll() {
      if (this.selected_ids.length === this.accounts.length) {
        this.selected_ids = [];
      } else {
        this.selected_ids = this.accounts.map((el) => el.id);
      }
    },
  },
  created() {
    this.getAccounts();
  },
  watch: {
    $is_confirm: function (el) {
      if (el) {
        this.deleteItem();
        this.$is_confirm = false;
      }
    },
    perPage() {
      this.pagination.current = 1
      this.getAccounts();
    },
    keyword() {
      this.getAccounts();
    },
  },
  computed: {
    icon() {
      if (this.selected_ids.length === this.accounts.length)
        return "mdi-close-box";
      if (
          this.selected_ids.length > 0 &&
          this.selected_ids.length !== this.accounts.length
      )
        return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
  },
};
</script>

<style scoped></style>
